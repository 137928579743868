import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"

import Header from '../components/header-blog'
import Footer from '../components/footer-common'
import PagerBlogarticle from '../components/pager-blogarticle'
import ButtonsShare from '../components/buttons-share'

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => (
      <img
        src={node.data.target.fields.file["ja-JP"].url}
        alt={
          node.data.target.fields.description
            ? node.data.target.fields.description["ja-JP"]
            : node.data.target.fields.title["ja-JP"]
        }
      />
    )
  }
}

export default ({ data, pageContext }) => (
  <div className="l-wrapper__blog__root--articlepage">
    <Header />
    <div className="l-wrapper__maincontents--blog">
      <section className="l-contents__main">
        <article className="p-blog-article">
          <header className="p-blog-article__header">
            <Img fluid={data.contentfulBlogPost.eyecatch.fluid} alt="（アイキャッチ画像説明）" className="c-eyecatch" />
            <h1 className="c-article__title">{data.contentfulBlogPost.title}</h1>
            <div className="c-timestamp">
              <div className="c-creation-time"><span className="u-datetime">{data.contentfulBlogPost.publishDate}</span><span className="u-unit"> 作成</span></div>
              <div className="c-last-update-time"><span className="u-datetime">{data.contentfulBlogPost.updatedAt}</span><span className="u-unit"> 最終更新</span></div>
            </div>
            <div className="c-author">
              <div className="u-icon-avater">
                <Img fluid={data.contentfulBlogPost.author.authoricon.fluid} alt="（アイコン画像説明）" />
              </div>
              <div className="u-name">{data.contentfulBlogPost.author.authorname}</div>
            </div>
            <ul className="c-categories">
              {data.contentfulBlogPost.category.map(cat => (
                <li className={cat.categorySlug} key={cat.id}>
                  <Link to={`/blog/cat/${cat.categorySlug}`} >
                    {cat.category}
                  </Link>
                </li>
              ))}
            </ul>
          </header>
          <div className="p-content">{documentToReactComponents(data.contentfulBlogPost.content.json,options)}</div>
          <div className="p-blog-article__footer">
            <PagerBlogarticle pageContext={pageContext} />
          </div>
        </article>
      </section>
      <section className="l-contents__sub" />
    </div>
    <ButtonsShare arg={`https://corp.mis.dev/blog/post/${data.contentfulBlogPost.slug}/`} shareText={`${data.contentfulBlogPost.title} 美園ITサービスブログ`} />
    <Footer />
  </div>
)

export const query = graphql`
query($id: String!) {
  contentfulBlogPost(id: { eq: $id }) {
    publishDate(formatString: "YYYY/MM/DD HH:mm")
    title
    slug
    updatedAt(formatString: "YYYY/MM/DD HH:mm")
    author {
      authorname
      authoricon {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    eyecatch {
      fluid(maxWidth: 900) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    category {
      category
      categorySlug
    }
    content {
      json
    }
  }
}
`